<template>
    <div class="modal-overlay" @click.self="$emit('close')">
        <div class="modal-content">
            <button class="close-btn" @click="closeMenu">&times;</button>
            <task-view :taskID="task.attributes.id" @close="$emit('close')" />
        </div>
    </div>
</template>

<script>
    import TaskView from '../../Tasks/views/actions/View.vue';
    export default {
        components: { TaskView },
        props: {
            task: { type: Object, required: true },
        },
        methods: {
            closeMenu(event) {
                event.stopPropagation();
                this.$emit('close');
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal-content {
        background: #fff;
        width: 80%;
        max-height: 90vh;
        padding: 1rem;
        position: relative;
        border-radius: 0.75rem;
        border: 1px solid #e5e7eb;

        overflow-y: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        scrollbar-width: thin;
        scrollbar-color: #bbb transparent;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #bbb;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #999;
        }

        .theme-dark & {
            background: #222;
            border-color: #444;
            scrollbar-color: #555 transparent;
        }
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #666;
        transition: color 0.2s;

        &:hover {
            color: #333;
        }

        .theme-dark & {
            color: #bbb;
            &:hover {
                color: #fff;
            }
        }
    }
</style>
