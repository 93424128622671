<template>
    <div class="task-menu">
        <button class="close-btn" @click="closeMenu">&times;</button>

        <div class="menu-section">
            <h4>Description</h4>
            <p v-html="shortDescription"></p>
        </div>

        <div class="dropdown-separator"></div>

        <div class="menu-section">
            <h4>Assigned</h4>
            <ul>
                <li v-for="user in task.attributes.users" :key="user.attributes.id">
                    <template v-if="user.attributes.avatar_url">
                        <img :src="userAvatarUrl(user)" alt="User Avatar" class="avatar" />
                    </template>
                    <template v-else>
                        <div class="avatar fallback-avatar">
                            {{
                                `${user.attributes.first_name.charAt(0).toUpperCase()}${user.attributes.last_name
                                    .charAt(0)
                                    .toUpperCase()}`
                            }}
                        </div>
                    </template>
                    {{ `${user.attributes.first_name} ${user.attributes.last_name}` }}
                </li>
            </ul>
        </div>

        <div class="dropdown-separator"></div>

        <div class="menu-section">
            <div class="footer-section">
                <div class="time-section">
                    <h4>Spent Time</h4>
                    <p>{{ formatSpentTime(task.attributes.total_spent_time) }}</p>
                </div>
                <div class="deadline-section">
                    <h4>Deadline</h4>
                    <template v-if="task.attributes.deadline">
                        <p>{{ formatDate(task.attributes.deadline) }}</p>
                    </template>
                    <template v-else>
                        <p>--/--/--</p>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            task: Object,
        },
        computed: {
            shortDescription() {
                const maxLength = 100;
                let description = this.task.attributes.description || '';

                if (description.length > maxLength) {
                    return description.substring(0, maxLength) + '...';
                }
                return description;
            },
        },
        methods: {
            closeMenu(event) {
                event.stopPropagation();
                this.$emit('close');
            },
            formatDate(dateStr) {
                const date = new Date(dateStr);

                return date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });
            },
            userAvatarUrl(user) {
                return `${process.env.VUE_APP_API_URL}/${user.attributes.avatar_url}`;
            },
            formatSpentTime(seconds) {
                if (seconds === 0) return '-';

                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);

                return `${hrs}h ${mins.toString().padStart(2, '0')}m`;
            },
        },
    };
</script>

<style scoped lang="scss">
    .task-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        padding: 16px;
        z-index: 1050;
        max-width: 350px;
        width: 100%;
        margin-top: 8px;
        font-family: Arial, sans-serif;
        color: #333;
        border: 1px solid #c4c4cf;
        overflow: hidden;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .dropdown-separator {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 8px 0;

        .theme-dark & {
            border-color: #555;
        }
    }

    .footer-section {
        display: flex;
        justify-content: space-between;
    }

    .close-btn {
        position: absolute;
        top: 8px;
        right: 12px;
        background: none;
        border: none;
        font-size: 22px;
        cursor: pointer;
        color: #999;
        transition: color 0.3s;
    }

    .close-btn:hover {
        color: #e74c3c;
    }

    .menu-header {
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .menu-section {
        margin-bottom: 12px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    h4 {
        margin: 0;
        font-size: 14px;
        color: #555;
        text-transform: uppercase;
    }

    p {
        margin: 4px 0;
        font-size: 14px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: flex;
        align-items: center;
        margin: 4px 0;
    }

    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #ddd;
        margin-right: 8px;
        object-fit: cover;

        .theme-dark & {
            border-color: #444;
        }
    }

    .fallback-avatar {
        background-color: #6c757d;
    }
</style>
