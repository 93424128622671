<template>
    <div v-click-outside="closeFilterModal" class="filter-container">
        <button class="filter-button" @click="toggleDropdown">
            <i class="at-btn__icon icon icon-filter"></i>
        </button>

        <transition name="slide-down">
            <div v-if="isDropdownVisible" ref="dropdown" class="details-content">
                <ul>
                    <li class="status-item" @click="clearAllProjects">Clear Filters</li>
                    <div class="dropdown-separator"></div>
                    <li
                        v-for="project in projects"
                        :key="project.attributes.id"
                        class="status-item"
                        :class="{ active: selectedProjectIds.includes(project.attributes.id) }"
                        @click="toggleProjectSelection(project.attributes.id)"
                    >
                        {{ project.attributes.name }}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
    import ProjectService from '../../../services/resource/project.service';

    const localStorageKey = 'ust.local.storage.kanban_project_select';

    export default {
        data() {
            return {
                isDropdownVisible: false,
                selectedProjectIds: JSON.parse(localStorage.getItem(localStorageKey)) || [],
                projects: [],
                projectService: new ProjectService(),
            };
        },

        computed: {
            isAllSelected() {
                return this.selectedProjectIds.length === this.projects.length;
            },
            projectId() {
                return this.$route.query.project_id || null;
            },
        },

        watch: {
            selectedProjectIds(projectID) {
                if (!projectID.length) {
                    const projectIds = this.projects.map(project => project.id);
                    this.$emit('selected-project', projectIds);
                } else {
                    this.$emit('selected-project', projectID);
                }

                localStorage.setItem(localStorageKey, JSON.stringify(projectID));
            },
        },

        async created() {
            await this.fetchProjects();
            this.returnAllProjectIds();
        },

        methods: {
            async fetchProjects() {
                try {
                    const { data } = await this.projectService.getWithFilters({ disable_pagy: true });
                    this.projects = data.data;
                } catch (error) {
                    console.log('Error fetching projects', error);
                }
            },

            toggleDropdown() {
                this.isDropdownVisible = !this.isDropdownVisible;
            },

            closeFilterModal() {
                this.isDropdownVisible = false;
            },

            toggleProjectSelection(projectID) {
                const index = this.selectedProjectIds.indexOf(projectID);
                if (index === -1) {
                    this.selectedProjectIds = [...this.selectedProjectIds, projectID];
                } else {
                    this.selectedProjectIds = this.selectedProjectIds.filter(id => id !== projectID);
                }
            },

            clearAllProjects() {
                this.selectedProjectIds = [];

                if (this.projectId) {
                    const newQuery = { ...this.$route.query };
                    delete newQuery.project_id;

                    this.$router.replace({ query: newQuery });
                }
            },

            returnAllProjectIds() {
                if (this.projectId) return this.sendQueryProject();

                const projectIds = this.projects.map(project => project.id);

                this.$emit('selected-project', projectIds);
            },

            sendQueryProject() {
                this.selectedProjectIds = [this.projectId];
            },
        },
    };
</script>

<style scoped lang="scss">
    .filter-container {
        position: relative;
        display: inline-block;
    }

    .dropdown-separator {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 8px 0;

        .theme-dark & {
            border-color: #555;
        }
    }

    .filter-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        &:hover {
            color: #000;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }

    .details-content {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        width: 300px;
        max-height: 350px;
        overflow-y: auto;
        padding: 1rem;
        background: #f9fafb;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .details-content::-webkit-scrollbar {
        width: 8px;
    }

    .details-content::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 4px;
    }

    .details-content::-webkit-scrollbar-thumb:hover {
        background-color: #999;
    }

    .details-content li {
        margin: 8px 0;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
    }

    .status-item:hover {
        background-color: #e5e7eb;
    }

    .theme-dark .status-item:hover {
        background-color: #555;
    }

    .status-item.active {
        background-color: #d1d5db;
        font-weight: bold;
    }

    .theme-dark .status-item.active {
        background-color: #444;
        color: #c4c4cf;
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    }

    .slide-down-enter,
    .slide-down-leave-to {
        opacity: 0;
        transform: translateY(-10px);
    }

    .slide-down-enter-to {
        opacity: 1;
        transform: translateY(0);
    }
</style>
