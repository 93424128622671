<template>
    <div class="card-view">
        <div
            ref="card"
            class="kanban-card"
            draggable="true"
            :class="{ 'is-dragging': isDragging, 'is-open': isPreviewMenuOpen }"
            @dragstart="handleDragStart"
            @dragover.prevent="dragOverTargetCard"
            @dragenter.prevent="dragOverTargetCard"
            @click="handleClick"
        >
            <span class="ref-text">{{ card.attributes.ref }}:</span>
            <span class="task-name">{{ card.attributes.task_name }}</span>

            <TaskPreviewMenu
                v-if="isPreviewMenuOpen"
                v-click-outside="closePreviewMenu"
                :task="card"
                @close="closePreviewMenu"
            />
        </div>
        <TaskDetailsModal
            v-if="isModalOpen"
            v-click-outside="closeModalWindow"
            :task="card"
            @close="closeModalWindow"
        />
    </div>
</template>

<script>
    import TaskPreviewMenu from '../../components/TaskPreviewMenu.vue';
    import TaskDetailsModal from '../../components/TaskDetailsModal.vue';
    export default {
        components: { TaskPreviewMenu, TaskDetailsModal },
        props: {
            card: Object,
            columnID: Number,
            statusID: Number,
        },
        data() {
            return {
                isDragging: false,
                lastClickTime: 0,

                isPreviewMenuOpen: false,
                isModalOpen: false,
            };
        },
        methods: {
            handleDragStart() {
                this.$emit('dragStart', this.card, this.columnID, this.statusID);
            },
            dragOverTargetCard() {
                this.$emit('drag-hover-card', this.card);
            },
            closePreviewMenu() {
                this.isPreviewMenuOpen = false;
            },
            closeModalWindow() {
                this.isModalOpen = false;
            },
            handleClick() {
                const now = Date.now();
                const timeDiff = now - this.lastClickTime;
                this.lastClickTime = now;

                if (timeDiff < 300) {
                    clearTimeout(this.clickTimeout);
                    this.isModalOpen = true;
                    this.closePreviewMenu;
                } else {
                    if (this.isPreviewMenuOpen) {
                        this.isPreviewMenuOpen = false;
                    } else {
                        this.clickTimeout = setTimeout(() => {
                            this.isPreviewMenuOpen = true;
                        }, 300);
                    }
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .kanban-card {
        position: relative;
        padding: 12px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        cursor: grab;
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &.is-dragging {
            z-index: 1000;
            cursor: grabbing;
        }

        .theme-dark & {
            background-color: #555;

            &:hover {
                transform: scale(1.02);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                background-color: #ffa60025;
            }
        }

        &:hover {
            transform: scale(1.02);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            background-color: #00abc15d;
        }

        &:active {
            cursor: grabbing;
        }

        .ref-text {
            font-weight: bold;
            margin-right: 5px;
            flex-shrink: 0;
            white-space: nowrap;
        }

        .task-name {
            font-weight: normal;
            flex-shrink: 0;
        }
    }
    .kanban-card.is-open {
        z-index: 1050;
    }
</style>
